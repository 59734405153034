import { render, staticRenderFns } from "./tableProduct.vue?vue&type=template&id=2e2b7607"
import script from "./tableProduct.vue?vue&type=script&lang=js"
export * from "./tableProduct.vue?vue&type=script&lang=js"
import style0 from "./tableProduct.vue?vue&type=style&index=0&id=2e2b7607&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports