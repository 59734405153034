<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="2" sm="6" md="6" lg="4">
        <b-button @click.prevent="openModal" class="btn-filter mr-2">
          <span class="d-none d-sm-flex align-items-center">
            <span class="mr-2">Select Items </span>
            <font-awesome-icon icon="chevron-right" class="pointer" />
          </span>
          <font-awesome-icon icon="chevron-right" class="d-sm-none" />
        </b-button>
        <span class="text-black text-nowrap">Selected {{ rows }} list</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          class="table-product"
          responsive
          hover
          :fields="fields"
          striped
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(name)="{ item }">
            <div class="font-bold">{{ item.name }}</div>

            <div v-html="item.description"></div>
          </template>
          <template v-slot:cell(imageUrl)="data">
            <div class="position-relative picture-text pic-table">
              <img
                v-if="data.item.imageUrl"
                class="product-image pt-0"
                :src="data.item.imageUrl"
                alt="product image"
              />
              <div v-else>
                <img
                  class="product-image pt-0"
                  :src="default_image"
                  alt="default Image"
                  srcset=""
                />
              </div>
            </div>
          </template>
          <template v-slot:cell(price)="{ item }">
            <InputText
              textFloat=""
              placeholder=""
              type="number"
              v-model="
                productSelectList[
                  productSelectList.findIndex((el) => el.product_id == item.id)
                ].price
              "
              @onKeyup="handleMaxPrice(item, $event)"
              name="round_day"
            />
          </template>
          <template v-slot:cell(quantity)="{ item }">
            <InputText
              textFloat=""
              placeholder=""
              type="number"
              v-model="
                productSelectList[
                  productSelectList.findIndex((el) => el.product_id == item.id)
                ].quantity
              "
              @onKeyup="handleMaxPrice(item, $event)"
              name="round_day"
              :v="
                v.$each[
                  productSelectList.findIndex((el) => el.product_id == item.id)
                ].quantity
              "
              :isValidate="
                v.$each[
                  productSelectList.findIndex((el) => el.product_id == item.id)
                ].$error
              "
            />
      
          </template>
          <template v-slot:cell(total)="{ item }">
            <InputText
              textFloat=""
              placeholder=""
              type="number"
              v-model="
                productSelectList[
                  productSelectList.findIndex((el) => el.product_id == item.id)
                ].total
              "
              :disabled="true"
            />
          </template>
          <template v-slot:cell(delete)="item">
            <b-button
              variant="link"
              @click="handleDeleteProduct(item)"
              class="text-gray"
            >
              <font-awesome-icon icon="trash-alt" class="ft-14 ml-2"
            /></b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />

    <ModalSelectProduct
      :useBy="useBy"
      :freezeProductList="freezeProductList"
      ref="modalSelectProduct"
      :isSap="true"
      :isAlreadyUse="isDisabled"
      @submit="selectedProduct"
    />
  </div>
</template>

<script>
import ModalSelectProduct from "@/components/ModalSelectProduct";
export default {
  components: { ModalSelectProduct },
  props: {
    filter: {
      required: false,
      defualt: {
        search: "",
        page: 1,
        take: 5,
      },
      type: Object,
    },
    rows: { required: false, default: 0, type: Number },
    fields: { required: false, type: Array },
    items: { required: false, type: Array },
    form: { required: false, type: Object },
    isBusy: { required: false, type: Boolean },
    useBy: { required: true },
    isDisabled: { required: true },
    freezeProductList: { required: false, type: Array },
    v: { required: false },
  },
  data() {
    return {
      productSelectList: [...this.freezeProductList],
    };
  },

  methods: {
    openModal() {
      this.$refs.modalSelectProduct.show(this.productSelectList, "sap");
    },

    pagination(val) {
      this.$emit("pagination", val);
    },
    handleChangeTake(val) {
      this.$emit("handleChangeTake", val);
    },
    handleSearch() {
      this.$emit("handleSearch");
    },
    handleMaxPrice(items, e) {
      // this.isBusy = true;
      const index = this.productSelectList.findIndex(
        (el) => el.product_id == items.id
      );
      this.$nextTick(() => {
        this.productSelectList[index].total =
          this.productSelectList[index].price *
          this.productSelectList[index].quantity;
        this.productSelectList = [...this.productSelectList];
      });
      // this.isBusy = false;
    },
    handleDeleteProduct({ item, index }) {
      if (!this.form.delete_product_id) this.form.delete_product_id = [];
      this.form.delete_product_id.push(item.id);
      this.form.add_product_id.splice(
        this.form.add_product_id.indexOf(item.id),
        1
      );
      // this.items.splice(index, 1);
      this.$emit("syncProductSelect", this.form);
    },

    selectedProduct(product) {
      let originalProduct =
        this.form.product.length == 0 ? [] : this.form.product;
      var addProduct = product.filter((id) => !originalProduct.includes(id));

      for (const productId of addProduct) {
        const findProductList = this.productSelectList.findIndex(
          (el) => el.product_id == productId
        );
        if (findProductList == -1) {
          this.productSelectList.push({
            product_id: productId,
            price: 0,
            quantity: 0,
            total: 0,
          });
        }
      }
      this.productSelectList = this.productSelectList.filter((el) =>
        product.includes(el.product_id)
      );
      this.form.add_product_id = addProduct;

      this.$emit("syncProductSelect", this.form);
    },
  },
};
</script>

<style>
.price::before {
  content: "฿ ";
}
.table-product table input {
  text-align: right;
}
</style>
